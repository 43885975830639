import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import Axios from 'axios'
Vue.prototype.$axios = Axios;

const routes = [
    {
        path: '/',
        name: 'frontpage',
        component: () => import('./views/Frontpage.vue'), 
        meta: { category: 'Frontpage', publicPage: true }
    },
    {
        path: '/product',
        name: 'product',
        component: () => import('./views/Frontpage.vue'), 
        meta: { category: 'Product', publicPage: true }
    },
    // {
    //     path: '/industries',
    //     name: 'industries',
    //     component: () => import('./views/Industries.vue'), 
    //     meta: { category: 'Industries Frontpage', publicPage: true }
    // },
		{
			path: '/industries/retail',
			name: 'industries-retail',
			component: () => import('./views/industries/Retail.vue'), 
			meta: { category: 'Industries', publicPage: true }
		},
		{
			path: '/industries/cafe-and-resturants',
			name: 'industries-cafeAndResturants',
			component: () => import('./views/industries/CafeAndResturants.vue'), 
			meta: { category: 'Industries', publicPage: true }
		},
		{
			path: '/industries/corporate',
			name: 'industries-corporate',
			component: () => import('./views/industries/Corporate.vue'), 
			meta: { category: 'Industries', publicPage: true }
		},
    // {
    //     path: '/support',
    //     name: 'support',
    //     component: () => import('./views/Support.vue'), 
    //     meta: { category: 'Support Frontpage', publicPage: true }
    // },
		{
			path: '/api',
			name: 'api',
			component: () => import('./views/Api.vue'), 
			meta: { category: 'Support', publicPage: true }
		},
		{
			path: '/documentation',
			name: 'documentation',
			component: () => import('./views/Documentation.vue'), 
			meta: { category: 'Support', publicPage: true }
		},
			{
				path: '/documentation/category/:categoryId?', //'/:slug?',
				name: 'documentation-category',
				component: () => import('./views/Documentation.vue'), 
				meta: { category: 'Support', publicPage: true }
			},
			{
				path: '/documentation/article/:articleId',
				name: 'documentation-article',
				// component: () => import('./views/DocumentationArticle.vue'), 
				component: () => import('./views/Documentation.vue'), 
				meta: { category: 'Support', publicPage: true }
			},
    {
        path: '/company',
        name: 'company',
        component: () => import('./views/Company.vue'), 
        meta: { category: 'Company', publicPage: true }
    },
    {
        path: '/getStarted',
        name: 'getStarted',
        component: () => import('./views/GetStarted.vue'), 
        meta: { category: 'Get Started', publicPage: true }
    },
    {
        path: '/partners',
        name: 'partners',
        component: () => import('./views/Partners.vue'), 
        meta: { category: 'Partners', publicPage: true }
    },
    
    
]

const router = new Router({
    mode: 'history', // default commented out
    // base: process.env.BASE_URL,
    routes: routes,
    scrollBehavior (to, from, savedPosition) {
        // console.log('scrollBehavior called');
        if (to.hash) {
            return {
                selector: to.hash
            }
        }
        else {
            return {
                x: 0, 
                y: 0,
                behavior: 'smooth',
            }
        }

    }
});

// router.beforeEach(async (to, from, next) => {
//     next()
// })

router.afterEach( async (to, from) => {
    // page tracker
    try {
        analytics.page( to.name , { 
            path: to.path,
            category: to.meta && to.meta.category ? to.meta.category : null,
        });
    } 
    catch (e) {
        console.log('error in segment');
        console.log(e);
    }
})

// router.onReady( async (to, from) => {
// 
// })

export default router;

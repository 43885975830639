<template>
    <div class="bg-blue-900 text-white flex justify-center">
        <nav class="py-12 container">
            <!-- <div class="grid grid-cols-4 gap-12">
                <section>
                    <h4 class="text-lg font-bold">Product</h4>
                    <nav class="">
                        <a href="#">Product features</a>
                        <a href="#">Pricing</a>
                        <a href="#">Security</a>
                        <a href="#">Template design</a>
                        <a href="#">API and integration</a>
                    </nav>
                </section>
                
                <section>
                    <h4 class="text-lg font-bold">Solutions</h4>
                    <nav class="">
                        <a href="#">Enterprise Retail businesses</a>
                        <a href="#">Smalll Busniess Owners</a>
                        <a href="#">Marketing and Creative</a>
                        <a href="#">Real Estate</a>
                    </nav>
                </section>
                
                <section>
                    <h4 class="text-lg font-bold">Resources</h4>
                    <nav class="">
                        <a href="">Help portal</a>
                        <a href="">Interactive training</a>
                        <a href="">Support</a>
                        <a href="">Project Management Guide</a>
                        <a href="">Partners</a>
                    </nav>
                </section>
                
                <section>
                    <h4 class="text-lg font-bold">Company</h4>
                    <nav class="">
                        <a href="">About us</a>
                        <a href="">Careers</a>
                        <a href="">Our customers</a>
                        <a href="">Blog</a>
                        <a href="">Partner program</a>
                        <a href="">Get Started</a>
                    </nav>
                </section>
            </div> -->
            
            <div class="w-full text-center">
                <!-- <div class="opacity-50">
                    <a href="#">Privacy Policy. Terms of Service. Cookie Preferences</a>
                </div> -->
                <div class="opacity-50">
                    CloudCast AS (928 234 126) © {{new Date().getFullYear()}}
                    <br>
                    Crafted in Oslo, Norway.
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                
            }
        },
    }
</script>

<style lang="scss" scoped>
    nav {
        
        a {
            @apply pb-1;
            @apply block;
        }
    }
</style>

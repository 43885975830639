<template>
    <div class="w-full h-full flex flex-col bg-white">
        <NavHeader class="flex-none " />
        
        <!-- 
        <div 
            class="fixed bottom-0 right-0 h-8 flex text-white font-bold text-center"
            style="z-index: 1000;">
            
            <div class="w-16 hidden sm:block bg-yellow-500">sm</div>
            <div class="w-16 hidden md:block bg-red-500">md</div>
            <div class="w-16 hidden lg:block bg-blue-500">lg</div>
            <div class="w-16 hidden xl:block bg-gray-500">xl</div>
            <div class="w-16 hidden 2xl:block bg-orange-500">2xl</div>
        </div> -->
       
        <div class="flex-grow overflow-auto h-full">
            <!-- <Transition name="fade-transition" mode="out-in"> -->
            <router-view id="routerView" />
            <!-- </Transition> -->
            
            <NavFooter/>
        </div>
        
    </div>
</template>

<script>
    import NavHeader from '@/components/NavHeader.vue';
    import NavFooter from '@/components/NavFooter.vue';

    export default {
        components: {
            NavHeader,
            NavFooter,
        },
    }
</script>
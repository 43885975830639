var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex",class:_vm.rowReverse ? ' flex-row-reverse' : ''},[(_vm.showImages)?_c('div',{staticClass:"w-1/2 pr-12 flex items-center justify-center"},[(_vm.items.find( s => s.active).animate)?_c('div',[_c('div',{staticClass:"relative",style:(`perspective: ${_vm.perspective}px; width: 600px; height: 300px;`)},[_c('div',{staticClass:"absolute top-0 right-0",style:(`
                        transform: rotateX(${_vm.rotate.x}deg) rotateY(${_vm.rotate.y}deg) rotateZ(${_vm.rotate.z}deg);
                        transform-style: preserve-3d;
                        transform-origin: center center;
                        width: 512px;
                        height: 288px;
                        margin-top: 55px;
                        margin-right: 30px;
                        filter: blur(10px);
                        background: rgba(0,0,0,.15);
                    `)}),_c('div',{staticClass:"absolute top-0 right-0",style:(`
                        transform: rotateX(${_vm.rotate.x}deg) rotateY(${_vm.rotate.y}deg) rotateZ(${_vm.rotate.z}deg);
                        transform-style: preserve-3d;
                        transform-origin: center center;
                        width: 512px;
                        height: 288px;
                        margin-top: 0px; 
                    `)},[_c('video',{staticClass:"absolute",attrs:{"autoplay":"","muted":"","loop":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":`/images/frontpage/sections/${ _vm.items.find( s => s.active).video }`,"type":"video/mp4"}})]),_c('div',{staticClass:"rounded-lg border-8 border-white absolute -m-1 inset-0 pointer-events-none",staticStyle:{"box-shadow":"0 0 0 2px #eee"}})])])]):(_vm.items.find( s => s.active))?_c('img',{attrs:{"src":`/images/frontpage/sections/${ _vm.items.find( s => s.active).image }`}}):_vm._e()]):_vm._e(),_c('div',{class:_vm.showImages ? 'w-1/2' : 'w-full'},_vm._l((_vm.items),function(s,i){return _c('div',{key:'s_'+i,staticClass:"flex",on:{"click":function($event){_vm.items.map(item => item.active = false); s.active = true;}}},[_c('div',{staticClass:"flex-none w-16 relative"},[(_vm.items[i+1])?_c('div',{staticClass:"border-l border-gray-200 h-full absolute ml-4 mt-2"}):_vm._e(),_c('div',{staticClass:"w-8 h-8 bg-white rounded-full shadow-lg flex items-center justify-center border border-gray-300 absolute"},[_c('div',{staticClass:"w-4 h-4 rounded-full",class:s.active ? 'bg-blue-900': 'bg-gray-200'})])]),_c('div',{staticClass:"flex-grow mb-8"},[_c('div',{staticClass:"inline-block text-blue-900 mt-1 mb-2 hover:font-bold cursor-pointer",class:s.active ? 'font-bold': ''},[_vm._v(" "+_vm._s(_vm.$t(s.heading))+" ")]),(s.active)?_c('p',[_vm._v(" "+_vm._s(_vm.$t(s.text))+" ")]):_vm._e()])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
Vue.use(Vuex)
import i18n from './i18n'

export default new Vuex.Store({
    state: {
        categories: null,
        articles: null,
    },
    
    getters: {
        getSupportCategories: state => {
            if (state.categories) {
                return state.categories;
            }
            return;
        },
        getSupportArticles: state => {
            if (state.articles) {
                return state.articles;
            }
            return;
        },
    },
    
    mutations: {
        setCategories(state, categories){
            state.categories = categories;
        },
        setArticles(state, articles){
            state.articles = articles;
        },
    },
    
    actions: {
        // async listArticles(context){
        //     const contentful = require('contentful');
        // 
        //     const client = contentful.createClient({
        //         space: process.env.VUE_APP_BLOG_SPACE_ID,
        //         accessToken: process.env.VUE_APP_BLOG_ACCESS_TOKEN,
        //     });
        // 
        //     let locale = 'en-US';
        //     // if (context.state.account.locale.language == 'nb') {
        //     //     locale = 'nb';
        //     // }
        //     // else {
        //     //     locale = 'en-US';
        //     // }
        // 
        //     const response = await client.getEntries({
        //         content_type: 'categories',
        //         locale,
        //     });
        // 
        //     this.commit('setCategories', response);
        // },
        
        // async fetchCategories(context){
        //     const contentful = require('contentful');
            
        //     const client = contentful.createClient({
        //         space: process.env.VUE_APP_BLOG_SPACE_ID,
        //         accessToken: process.env.VUE_APP_BLOG_ACCESS_TOKEN,
        //     });
            
        //     let locale = 'en-US';
        //     // if (context.state.account.locale.language == 'nb') {
        //     //     locale = 'nb';
        //     // }
        //     // else {
        //     //     locale = 'en-US';
        //     // }
            
        //     const response = await client.getEntries({
        //         content_type: 'category',
        //         locale,
        //     });
            
        //     // const response = await client.getContentTypes({
        //         // content_type: 'layout',
        //         // locale,
        //     // });
            
        //     this.commit('setCategories', response);
        // },
        
        // async fetchArticles(context){
        //     const contentful = require('contentful');
            
        //     const client = contentful.createClient({
        //         space: process.env.VUE_APP_BLOG_SPACE_ID,
        //         accessToken: process.env.VUE_APP_BLOG_ACCESS_TOKEN,
        //     });
            
        //     let locale = 'en-US';
        //     // if (context.state.account.locale.language == 'nb') {
        //     //     locale = 'nb';
        //     // }
        //     // else {
        //     //     locale = 'en-US';
        //     // }
            
        //     const response = await client.getEntries({
        //         content_type: 'article',
        //         locale,
        //     });
            
        //     this.commit('setArticles', response);
        // },
		
		
		
		
		
		async listSupportCategories(context){
			// if (localStorage.categories != 'undefined') {
			// 	this.commit('setCategories', JSON.parse(localStorage.categories));
			// 	return JSON.parse(localStorage.categories);
			// }
			
			const contentful = require('contentful');
			
			const client = contentful.createClient({
				space: process.env.VUE_APP_BLOG_SPACE_ID,
				accessToken: process.env.VUE_APP_BLOG_ACCESS_TOKEN,
			});
			
			const response = await client.getEntries({
				content_type: 'category',
				locale: 'en-US',
			});
			
			if (response) {
				// localStorage.categories = JSON.stringify( response );
				this.commit('setCategories', response);
				return response;
			}
		},
		
		async listSupportArticles(context){
			// if (localStorage.articles != 'undefined') {
			// 	this.commit('setArticles', JSON.parse(localStorage.articles));
			// 	return JSON.parse(localStorage.articles);
			// }
			
			const contentful = require('contentful');
			
			const client = contentful.createClient({
				space: process.env.VUE_APP_BLOG_SPACE_ID,
				accessToken: process.env.VUE_APP_BLOG_ACCESS_TOKEN,
			});
			
			const response = await client.getEntries({
				content_type: 'article',
				locale: 'en-US',
			});
			
			if (response) {
				// localStorage.articles = JSON.stringify( response );
				this.commit('setArticles', response);
				return response;
			}
		},
		
    }
})

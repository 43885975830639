<template>
    <div class="my-24 max-w-md mx-auto">
        <h2 class="h1 text-blue-900 text-center mb-4">
            {{$t('speakToAnExpert.heading')}}
        </h2>
        
        <form 
            v-if="!status"
            @submit.prevent="submitForm()">
            
            <select v-model="form.interest">
                <option :value="null" disabled>{{$t('speakToAnExpert.wantToKnowMoreAbout')}}</option>
                <option 
                    v-for="(interest, i) in interests"
                    :key="'interest_'+i"
                    :value="interest">
                    
                    {{$t('speakToAnExpert.interests.'+interest)}}
                </option>
            </select>
            
            <input 
                v-model="form.firstName"
                type="text"
                :placeholder="$t('general.firstName')"
            />
            
            <input 
                v-model="form.lastName"
                type="text"
                :placeholder="$t('general.lastName')"
            />
            
            <input 
                v-model="form.companyEmail"
                type="email"
                :placeholder="$t('general.companyEmail')"
            />
            
            <input 
                v-model="form.companyName"
                type="text"
                :placeholder="$t('general.companyName')"
            />
            
            <button type="submit" class="bg-yellow-600 px-4 py-3 text-white rounded">
                {{$t('speakToAnExpert.getInTouch')}}
            </button>
        </form>
        <div 
            v-else
            class="p-8 mx-auto text-center">
            
            <i class="fas fa-thumbs-up text-yellow-600 text-5xl" />
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                interests: [
                    'howToHelp',
                    'pricing',
                    'hardwareAndTechnical',
                    'securityAndArchitecture',
                    'designAndTemplates',
                    'partnerProgram',
                ],
                form: {
                    formType: 'speakToAnExpert',
                    interest: null,
                    firstName: null,
                    lastName: null,
                    companyEmail: null,
                    companyName: null,
                },
                status: null,
            }
        },
        
        methods: {
            async submitForm(){
                const response = await this.$axios.post('https://9qqwueeee8.execute-api.eu-west-1.amazonaws.com/prod/form', this.form);
                console.log(response);
                this.status = response;
            }
        },
    }
</script>
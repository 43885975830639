export default {
    'en': {
        currency: {
            style: 'currency',
            currency: 'USD'
        }
    },
    'no': {
        currency: {
            style: 'currency',
            currency: 'NOK'
        }
    },
    'es': {
        currency: {
            style: 'currency',
            currency: 'EUR',
            currencyDisplay: 'symbol'
        }
    },
    'jp': {
        currency: {
            style: 'currency',
            currency: 'JPY',
            currencyDisplay: 'symbol'
        }
    }
};

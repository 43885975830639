// AccountMixins.js

import SectionPoints from '@/components/SectionPoints.vue';
import SpeakToExpert from '@/components/SpeakToExpert.vue';

export default {
    components: {
        SectionPoints,
        SpeakToExpert,
    },
    
    data(){
        return {
			industries: [
				{
					code: 'retail',
					routeName: 'industries-retail'
				},
				{
					code: 'cafeAndResturants',
					routeName: 'industries-cafeAndResturants'
				},
				{
					code: 'corporate',
					routeName: 'industries-corporate'
				},
			],
			
            sections: {
                general: [
                    { active: true,  image: 'handheld_scanning.png', heading: 'product.general.fromCatalog.heading', text: 'product.general.fromCatalog.text'},
                    { active: false,  image: 'smartTemplates.png', heading: 'product.general.smartTemplates.heading', text: 'product.general.smartTemplates.text'},
                    { active: false, animate: true, video: 'animated_templates2.mp4', heading: 'product.general.animatedPosters.heading', text: 'product.general.animatedPosters.text'},
                    { active: false,  image: 'campaign_elements2.png', heading: 'product.general.campaignElements.heading', text: 'product.general.campaignElements.text'},
                    { active: false,  image: 'mediafiles.png', heading: 'product.general.mediaFiles.heading', text: 'product.general.mediaFiles.text'},
                    { active: false,  image: 'integrated_player4.png', heading: 'product.general.integratedPlayer.heading', text: 'product.general.integratedPlayer.text'},
                ],
                
                handheld: [
                    { active: true, image: '', heading: 'product.handheld.features.scanQr.heading', text: 'product.handheld.features.scanQr.text'},
                    { active: false, image: '', heading: 'product.handheld.features.scanProduct.heading', text: 'product.handheld.features.scanProduct.text'},
                    { active: false, image: '', heading: 'product.handheld.features.chooseTemplate.heading', text: 'product.handheld.features.chooseTemplate.text'},
                    { active: false, image: '', heading: 'product.handheld.features.preview.heading', text: 'product.handheld.features.preview.text'},
                    { active: false, image: '', heading: 'product.handheld.features.mobilephones.heading', text: 'product.handheld.features.mobilephones.text'},
                ],
                
                admin: [
                    { active: true, image: '', heading: 'product.admin.features.fullOverview.heading', text: 'product.admin.features.fullOverview.text' },
                    { active: false, image: '', heading: 'product.admin.features.manageRemote.heading', text: 'product.admin.features.manageRemote.text' },
                    { active: false, image: '', heading: 'product.admin.features.prepareContent.heading', text: 'product.admin.features.prepareContent.text' },
                    { active: false, image: '', heading: 'product.admin.features.giveAccessToTemplate.heading', text: 'product.admin.features.giveAccessToTemplate.text' },
                    { active: false, image: '', heading: 'product.admin.features.designTool.heading', text: 'product.admin.features.designTool.text' },
                    { active: false, image: '', heading: 'product.admin.features.accessAndRoles.heading', text: 'product.admin.features.accessAndRoles.text' },
                ],
            }
        }
    },
}

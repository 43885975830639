<template>
    <div 
        class="flex"
        :class="rowReverse ? ' flex-row-reverse' : ''">
        
        
        <div 
            v-if="showImages" 
            class="w-1/2 pr-12 flex items-center justify-center">
            
            <div v-if="items.find( s => s.active).animate">
                
                <div 
                    :style="`perspective: ${perspective}px; width: 600px; height: 300px;`"
                    class="relative">
                    
                    <div 
                        class="absolute top-0 right-0"
                        :style="`
                            transform: rotateX(${rotate.x}deg) rotateY(${rotate.y}deg) rotateZ(${rotate.z}deg);
                            transform-style: preserve-3d;
                            transform-origin: center center;
                            width: 512px;
                            height: 288px;
                            margin-top: 55px;
                            margin-right: 30px;
                            filter: blur(10px);
                            background: rgba(0,0,0,.15);
                        `"
                    />
                    
                    <div 
                        class="absolute top-0 right-0"
                        :style="`
                            transform: rotateX(${rotate.x}deg) rotateY(${rotate.y}deg) rotateZ(${rotate.z}deg);
                            transform-style: preserve-3d;
                            transform-origin: center center;
                            width: 512px;
                            height: 288px;
                            margin-top: 0px; 
                        `">
                        
                        <video autoplay muted loop class="absolute">
                            <source 
                                :src="`/images/frontpage/sections/${ items.find( s => s.active).video }`"
                                type="video/mp4" 
                            />
                        </video>
                        
                        <div 
                            class="rounded-lg border-8 border-white absolute -m-1 inset-0 pointer-events-none"
                            style="box-shadow: 0 0 0 2px #eee;">
                            
                        </div>
                    </div>
                </div>
                
                <!-- x: <input type="number" v-model="rotate.x" class="border p-2 m-2" />
                <br>
                y: <input type="number" v-model="rotate.y" class="border p-2 m-2" />
                <br>
                z: <input type="number" v-model="rotate.z" class="border p-2 m-2" />
                <br>
                perspective: <input type="number" v-model="perspective" class="border p-2 m-2" /> -->
            </div>
                
            <img 
                v-else-if="items.find( s => s.active)"
                :src="`/images/frontpage/sections/${ items.find( s => s.active).image }`"
            />
        </div>
        <div :class="showImages ? 'w-1/2' : 'w-full'">
            
            <div 
                v-for="(s,i) in items"
                :key="'s_'+i"
                class="flex"
                @click="items.map(item => item.active = false); s.active = true;">
                
                <div class="flex-none w-16 relative">
                    <div 
                        v-if="items[i+1]"
                        class="border-l border-gray-200 h-full absolute ml-4 mt-2" 
                    />
                    
                    <div class="w-8 h-8 bg-white rounded-full shadow-lg flex items-center justify-center border border-gray-300 absolute">
                        <div 
                            class="w-4 h-4 rounded-full" 
                            :class="s.active ? 'bg-blue-900': 'bg-gray-200'"
                        />
                    </div>
                </div>
                <div class="flex-grow mb-8">
                    <div 
                        class="inline-block text-blue-900 mt-1 mb-2 hover:font-bold cursor-pointer"
                        :class="s.active ? 'font-bold': ''">
                        
                        {{ $t(s.heading) }}
                    </div>
                    
                    <p v-if="s.active">
                        {{ $t(s.text) }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: [
            'items',
            'rowReverse',
            'showImages',
        ],
        
        data () {
            return {
                rotate: {
                    x: 16,
                    y: 25,
                    z: -16,
                },
                perspective: 1000,
            }
        },
    }
</script>
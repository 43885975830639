import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/tailwind.css'
import '@/assets/css/styles.scss'


Vue.config.productionTip = false
import i18n from './i18n'

// import { PrismEditor } from 'vue-prism-editor';
// import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles
// Vue.component('PrismEditor', PrismEditor);

import Axios from 'axios'
Vue.prototype.$axios = Axios;

// Global Mixin
import WwwMixins from '@/mixins/WwwMixins'
Vue.mixin(WwwMixins);

// VUE Init
new Vue({
    store,
    router,
    render: h => h(App),
    i18n,
    async mounted() {
        document.title = process.env.VUE_APP_TITLE; // + ' v' + process.env.VUE_APP_VERSION
        if (analytics && process.env.VUE_APP_SEGMENT_KEY) {
            analytics.load( process.env.VUE_APP_SEGMENT_KEY );
        }
    }
}).$mount('#app')
